import React from 'react'
import styles from './ArtistImage.module.css'

const cloudName = 'sublimate'
const linkBase = 'https://res.cloudinary.com/' + cloudName + '/image/upload/'

function convertFormat (filename, extension) {
  if (!filename) return ''
  let splitName = filename.split('.')
  splitName.pop()
  return splitName.join('.') + '.' + extension
}

export default function ({alt, publicId}) {
  return (
    <div className={styles.artistImage}>
      <div
        style={{
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundImage: `url(${linkBase + 'c_fill,e_blur:200,q_30,w_50,h_50/' + publicId})`
        }}
      >
        <picture>
          <source
            type="image/webp"
            srcSet={linkBase + 'c_fill,g_center,h_600,w_600/' + convertFormat(publicId, 'webp')}
          />
          <source
            type="image/jpeg"
            srcSet={linkBase + 'c_fill,g_center,h_600,w_600/' + publicId}
          />
          <img
            src={linkBase + 'c_fill,g_center,h_600,w_600/' + publicId}
            alt={alt}
          />
        </picture>
      </div>
    </div>
  )
}
