import React from 'react'
import styles from './Artist.module.css'
import ArtistImage from '../components/ArtistImage'

const ArtistLink = ({url, label, className}) =>
  <a className={className} href={url} target="_blank" rel="noopener noreferrer">
    {label}
  </a>

const Artist = ({name, affiliation, html, links, image}) =>
  <div className={styles.artist}>
    <div className={styles.image}>
      <ArtistImage publicId={image} alt={name} />
    </div>
    <div className={styles.info}>
      <h3 className={styles.name}>{name}</h3>
      <h6 className={styles.affiliation}>{affiliation}</h6>
      <div className={styles.links}>
        {links.map(link => <ArtistLink className={styles.link} key={link.url} {...link} />)}
      </div>
      <div className={styles.line} />
    </div>
    <div className='clearfix' />
  </div>

export default Artist
