import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Artist from '../components/Artist'
import flattenNodes from '../util/flattenNodes'

const ArtistsPage = ({data}) => {
  const artists = flattenNodes(data)

  return (
    <Layout>
      {artists.map(artist => <Artist key={artist.id} {...artist} />)}
    </Layout>
  )
}

export const pageQuery = graphql`
  query ArtistsQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___position], order: ASC }
      filter: { frontmatter: { key: { eq: "artist" }}}
    ) {
      edges {
        node {
          id
          frontmatter {
            name
            affiliation
            image
            position
            links {
              url
              label
            }
          }
        }
      }
    }
  }
`

export default ArtistsPage
